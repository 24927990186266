import { IDENTITY_CONFIG } from "./authConst";
import { UserManager, Log, OidcClient, User } from "oidc-client";
import { alert, toast } from "../components/Helper_component/Swal";
import Swal from "sweetalert2";
import config from "./config";
import * as Sentry from "@sentry/react";

const userManager = new UserManager(IDENTITY_CONFIG);

export class AuthService {
  UserManager: any; //We're accessing private functions so IDENTITY_CONFIG the proper type will cause errors
  userSetListeners = [] as ((user: User | null) => void)[];
  User = null as User | null;

  constructor() {
    this.UserManager = userManager;
    // Logger
    Log.logger = console;
    Log.level = Log.WARN;
    this.UserManager.events.addUserLoaded((user) => {
      this.setUser(user);
    });
    this.UserManager.events.addSilentRenewError((e) => {
      console.error("silent renew error", e.message);
    });

    this.UserManager.events.addAccessTokenExpired(async () => {
      try {
        await this.signinSilent();
      } catch {
        this.signOut();
        toast.fire("Your session expired");
      }
    });

    this.checkTransferredUser();
  }

  checkTransferredUser() {
    let encodedUserData = new URLSearchParams(window.location.search).get(
      "userdata"
    );

    if (!encodedUserData) return;

    let decoded = encodedUserData;
    this.UserManager.storeUser(User.fromStorageString(decoded));
    window.history.replaceState(
      {},
      document.title,
      `${window.location.protocol}//${window.location.host}${window.location.pathname}`
    );
  }

  async createUserTransfer() {
    let user = await this.getUser();
    if (!user) return "";
    let data = user.toStorageString();
    return `userdata=${data}`;
  }

  getUser = async () => {
    const user = await this.UserManager.getUser();
    return user;
  };

  setUser(user: User | null) {
    this.User = user;
    return new Promise((resolve) => {
      if (!!user) {
        Sentry.setUser({ id: user.profile.sub, email: user.profile.email });
      } else Sentry.configureScope((scope) => scope.setUser(null));
      this.userSetListeners.forEach((fn) => fn(user));
      resolve(user);
    });
  }

  async getAndSetUser() {
    return this.setUser(await this.getUser());
  }

  onUserSet(fn) {
    this.userSetListeners.push(fn);
  }
  removeOnUserSet(fn) {
    this.userSetListeners = this.userSetListeners.filter((a) => a !== fn);
  }

  async startRegistration() {
    var self = this;
    var workerFunction = async function (resolve) {
      var client = new OidcClient(IDENTITY_CONFIG);
      var res = await client.createSigninRequest();
      var uri = encodeURIComponent(
        res.url.replace(IDENTITY_CONFIG.authority ?? "", "")
      );
      var regWindow = window.open(
        `${IDENTITY_CONFIG.authority}/register/manual?returnUrl=${uri}`,
        "RegistrationWindow",
        "height=600,width=500"
      );
      window["popupCallback_" + res.state.id] = async function (a) {
        regWindow?.close();
        try {
          await self.UserManager._signinEnd(a);
        } catch (e: any) {
          //This is an extremely ugly workaround for it giving an error, even if it's working, so we'll ignore this partical error
          if (e.message !== "No state in response") throw e;
        }

        await self.signIn();
        resolve();
      };
    };
    return new Promise((resolve) => {
      workerFunction(resolve);
    });
  }

  async promptSignIn(options = {}) {
    var res = await alert.fire({
      title: "Sign in",
      text: "We just need to know who you are first",
      cancelButtonText: "Sign me in",
      confirmButtonText: "Create account",
      showCancelButton: true,
      ...options,
    });
    if (res.value && res.value === true) {
      return this.startRegistration();
    } else if (res.dismiss && res.dismiss === Swal.DismissReason.cancel) {
      return await this.signIn(true);
    } else return null;
  }

  signinSilent = async () => {
    await this.UserManager.signinSilent();
  };
  async signinSilentCallback() {
    await this.UserManager.signinSilentCallback();
    await this.getAndSetUser();
  }

  createSigninRequest = () => {
    return this.UserManager.createSigninRequest();
  };

  async getIdToken() {
    let user = await this.getUser();
    return (user || {}).id_token;
  }

  async getAccessToken() {
    let user = await this.getUser();
    return (user || {}).access_token;
  }

  async signOutPopupCallback() {
    await this.UserManager.signoutPopupCallback();
  }

  async signOut() {
    toast.fire("Signing out...", "", "info");
    let idToken = await this.getIdToken();
    this.UserManager.removeUser();
    this.setUser(null);
    try {
      await this.UserManager.signoutPopup({ id_token_hint: idToken });
    } catch {
      //We'll ignore this, can't do much about it
    } finally {
      await this.UserManager.clearStaleState();
    }
    toast.fire("Signed out!");
  }

  async signInPopupCallback() {
    await this.UserManager.signinPopupCallback();
  }

  async signIn(fromInteraction = false) {
    var user = await this.getUser();
    if (user) {
      this.setUser(user);
      return user;
    }
    try {
      var res = await this.UserManager.signinPopup();
      toast.fire({
        title: "Signed in",
      });
      return res;
    } catch (err: any) {
      toast.close();
      if (err.message === "Popup window closed") {
        return;
      }
      console.error(err);
      alert
        .fire({
          title: "Couldn't sign in",
          html: `Feel free to reach out to <a href='${config.supportEmail}'>${config.supportEmail}</a> if you're having issues`,
          cancelButtonText: "Support",
          showCancelButton: true,
        })
        .then((res) => {
          if (res.dismiss && res.dismiss === Swal.DismissReason.cancel) {
            window.location.href = "mailto:" + config.supportEmail;
          }
        });
      return err;
    }
  }
}

const authService = new AuthService();
export default authService;
