import { useCallback, useEffect, useState } from 'react';
import { useDropzone, FileRejection } from 'react-dropzone';
import { ArrowUpTrayIcon, XMarkIcon } from '@heroicons/react/24/solid';
import '../../../styles/Dropzone.css';

interface DropzoneProps {
    className?: string;
    onFilesChange: (files: PreviewFile[]) => void;
}

interface PreviewFile extends File {
    preview: string;
}

const Dropzone: React.FC<DropzoneProps> = ({ className, onFilesChange }) => {
    const [files, setFiles] = useState<PreviewFile[]>([]);
    const [rejected, setRejected] = useState<FileRejection[]>([]);

    const onDrop = useCallback((acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
        if (acceptedFiles?.length) {
            const newFiles = acceptedFiles.map(file =>
                Object.assign(file, { preview: URL.createObjectURL(file) })
            ) as PreviewFile[];
            setFiles(previousFiles => [...previousFiles, ...newFiles]);
            onFilesChange([...files, ...newFiles]);
        }

        if (rejectedFiles?.length) {
            setRejected(previousFiles => [...previousFiles, ...rejectedFiles]);
        }
    }, [files, onFilesChange]);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: {
            'image/*': []
        },
        maxSize: 2048 * 1000,
        onDrop
    });

    useEffect(() => {
        return () => files.forEach(file => URL.revokeObjectURL(file.preview));
    }, [files]);

    const removeFile = (name: string) => {
        const newFiles = files.filter(file => file.name !== name);
        setFiles(newFiles);
        onFilesChange(newFiles);
    };

    const removeAll = () => {
        setFiles([]);
        setRejected([]);
        onFilesChange([]);
    };


    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        if (!files?.length) return;

        const formData = new FormData();
        files.forEach(file => formData.append('file', file));
        formData.append('upload_preset', 'friendsbook');

        const URL = process.env.NEXT_PUBLIC_CLOUDINARY_URL as string;
        const data = await fetch(URL, {
            method: 'POST',
            body: formData
        }).then(res => res.json());
    };

    return (
        <form>
            <div
                {...getRootProps({
                    className: `${className} dropzone ${isDragActive ? 'dropzone-active' : ''}`
                })}
            >
                <input {...getInputProps()} />
                <div className="dropzone-content">
                    <span className="dropzoneUploadStyle">
                        <ArrowUpTrayIcon className="dropzone-icon" />
                    </span>
                    {isDragActive ? (
                        <p>Drop the files here ...</p>
                    ) : (
                        <p>Drag & drop files here, or click to select files</p>
                    )}
                </div>
            </div>

            <section className="preview-section">
                <div className="preview-header">
                    <button
                        type="button"
                        onClick={removeAll}
                        className="remove-all-button"
                    >
                        Remove all files
                    </button>
                    <span>* Please upload just image formatted files under 2mb</span>
                </div>

                <ul className="accepted-files-list">
                    {files.map(file => (
                        <li key={file.name} className="accepted-file-item">
                            <img
                                src={file.preview}
                                alt={file.name}
                                width={70}
                                height={70}
                                onLoad={() => {
                                    URL.revokeObjectURL(file.preview);
                                }}
                            />
                            <button
                                type="button"
                                className="remove-file-button"
                                onClick={() => removeFile(file.name)}
                            >
                                <XMarkIcon />
                            </button>
                            <p className="file-name">
                                {file.name}
                            </p>
                        </li>
                    ))}
                </ul>
            </section>
        </form>
    );
};

export default Dropzone;
