import React from "react";
import Wrapper, { WrapperProps } from "./Wrapper";
import Loader, { Loading } from "../Loader";

export const LoadingWrapper = function({ loaders, children, wrapperProps } : { loaders:  Loading[], children?: any, wrapperProps?: WrapperProps } ) {
    
    const isLoading = loaders.some(a => a.isLoading);

    if (!isLoading) return <Wrapper {...wrapperProps}>{children}</Wrapper>
    return <Wrapper {...wrapperProps}>
        <Loader loaders={loaders} />
    </Wrapper>

}