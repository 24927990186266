
import React from "react";
import authService from "../../utils/AuthService";
import IContext, { IContextActions, } from "../../models/IContext";
import { observer } from "mobx-react-lite";
import useRootStore from "../../hooks/rootStoreHook";

/**
 * @deprecated Has been replaced by RootStoreContext
 */
interface IAuthContextState {
    /**
 * @deprecated Has been replaced by RootStoreContext
 */
    authenticated: boolean,
    /**
 * @deprecated Has been replaced by RootStoreContext
 */
    user: any,
}
/**
 * @deprecated Has been replaced by RootStoreContext
 */
export interface IAuthContext extends IContext<IAuthContextState, IAuthContextActions> {

}
/**
 * @deprecated Has been replaced by RootStoreContext
 */
interface IAuthContextActions extends IContextActions {
    signIn(): void;
    signOut(): void;
    promptSignIn(opts: any): Promise<any>
}

/**
 * @deprecated Has been replaced by RootStoreContext
 */
export const AuthContext = React.createContext<IAuthContext | null>(null);

/**
 * @deprecated Has been replaced by RootStoreContext
 */
export const AuthConsumer = AuthContext.Consumer;

/**
 * @deprecated Has been replaced by RootStoreContext
 */
export const AuthProvider = observer((props: any) => {

    const { sessionStore } = useRootStore();

    const actions = {
        /**
 * @deprecated Has been replaced by RootStoreContext
 */
        signIn: () => {
            authService.signIn();
        },
        /**
 * @deprecated Has been replaced by RootStoreContext
 */
        signOut: () => {
            authService.signOut();
        },
        /**
 * @deprecated Has been replaced by RootStoreContext
 */
        promptSignIn: (opts = {}) => authService.promptSignIn(opts)
    } as IAuthContextActions;
    const state = { state: { user: sessionStore.user, authenticated: sessionStore.authenticated }, actions };


    return <AuthContext.Provider value={state}>{props.children}</AuthContext.Provider>
});