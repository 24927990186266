
import React from "react";
import IContext, { IContextActions } from "../../models/IContext";
import { License } from "../../models/License";
import { Spaces, Space } from "../../models/Spaces";
import { observer } from "mobx-react-lite";
import useRootStore from "../../hooks/rootStoreHook";

/**
 * @deprecated Has been replaced by RootStoreContext
 */
interface ILicenseContextState {
    license: License | null | undefined,
    spaces: Spaces | null | undefined,
    currentSpace: Space | null | undefined,
    hasSpaces: boolean
}
/**
 * @deprecated Has been replaced by RootStoreContext
 */
interface ILicenseContext extends IContext<ILicenseContextState, ILicenseContextActions> {

}
/**
 * @deprecated Has been replaced by RootStoreContext
 */
interface ILicenseContextActions extends IContextActions {
    getCurrentLicense(payload?: boolean): Promise<License | null>,
    hasLicense(): Promise<boolean>,
    setCurrentSpace(id: string): void,
    getSpaces(): Promise<Spaces | null>
}

/**
 * @deprecated Has been replaced by RootStoreContext
 */
export const LicenseContext = React.createContext<ILicenseContext | null>(null);

/**
 * @deprecated Has been replaced by RootStoreContext
 */
export const LicenseConsumer = LicenseContext.Consumer;

/**
 * @deprecated Has been replaced by RootStoreContext
 */
export const LicenseProvider = observer((props: any) => {

    const { sessionStore } = useRootStore();

    const actions = {
        /**
 * @deprecated Has been replaced by RootStoreContext
 */
        //Only async for backward compatibility
        async getCurrentLicense(): Promise<License | null> {
            return sessionStore.license;
        },
        /**
 * @deprecated Has been replaced by RootStoreContext
 */
        //Only async for backward compatibility
        async hasLicense(): Promise<boolean> {
            return !!sessionStore.license;
        },
        /**
 * @deprecated Has been replaced by RootStoreContext
 */
        //Only async for backward compatibility
        async setCurrentSpace(id: string) {
            let spaces = sessionStore.spaces;
            let space = spaces?.userSpaces?.find(a => a.id === id) ?? spaces!?.collaborationSpaces.find(a => a.id === id);
            if (!space) throw Error(`No space with ID ${id} found`);
            sessionStore.setCurrentSpace(space);
        },
        /**
 * @deprecated Has been replaced by RootStoreContext
 */
        //Only async for backward compatibility
        async getSpaces() {
            return sessionStore.spaces;
        }
    } as ILicenseContextActions;

    return <LicenseContext.Provider value={{
        /**
 * @deprecated Has been replaced by RootStoreContext
 */
        state: { license: sessionStore.license, spaces: sessionStore.spaces, currentSpace: sessionStore.currentSpace, hasSpaces: !!sessionStore.spaces }, actions
    }}>{props.children}</LicenseContext.Provider>
    // }
});