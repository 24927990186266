import React, { Suspense, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import TagManager from "react-gtm-module";
import * as Sentry from "@sentry/react";
import { createRoot } from "react-dom/client";

import "animate.css/animate.min.css";
import "./styles/app.scss";

import Header from "./components/Layout/Header";
import Sidebar from "./components/Layout/Sidebar";
import Footer from "./components/Layout/Footer";

import {
  SignInCallback,
  SignOutCallback,
  SignInSilentCallback,
} from "./components/Auth/Callback";
import { AuthProvider } from "./components/Auth/AuthProvider";
import { AuthenticatedRoute } from "./components/Auth/AuthenticatedRoute";
import { LicenseProvider } from "./components/Auth/LicenseProvider";
import { ApiProvider } from "./utils/api/ApiProvider";
import { LoadingWrapper } from "./components/LoadingWrapper";
import { AuthorizationProvider } from "./components/Auth/AuthorizationProvider";

import RootStore from "./store/RootStoreContext";
import lazyWithRetry from "./LazyWithRetry";
import GlobalStandardFinder from "./components/Tools/GlobalStandardFinder/GlobalStandardFinder";
import GlobalLegislationFinder from "./components/Tools/GlobalLegislationFinder/GlobalLegislationFinder";
import CreateFeedback from "./components/Feedback/CreateFeedback";

const Tools = lazyWithRetry(() => import("./components/Tools/Tools"));
const Products = lazyWithRetry(() => import("./components/Products/Products"));
const LegislationFinder = lazyWithRetry(
  () => import("./components/Tools/LegislationFinder/LegislationFinder")
);
const DocumentationGenerator = lazyWithRetry(
  () =>
    import("./components/Tools/DocumentationGenerator/DocumentationGenerator")
);
const DocumentationRequirement = lazyWithRetry(
  () =>
    import(
      "./components/Tools/DocumentationRequirement/DocumentationRequirement"
    )
);
const NotifiedBodies = lazyWithRetry(
  () => import("./components/Tools/NotifiedBodies/NotifiedBodies")
);
const DocumentationManager = lazyWithRetry(
  () => import("./components/Tools/DocumentationManager/DocumentationManager")
);
const Dashboard = lazyWithRetry(
  () => import("./components/Dashboard/Dashboard")
);
const SharedFile = lazyWithRetry(
  () => import("./components/SharedFile/SharedFile")
);
const HarmonisedStandards = lazyWithRetry(
  () => import("./components/Tools/HarmonisedStandards/HarmonisedStandards")
);
const Store = lazyWithRetry(() => import("./components/Store/StoreOptions"));
const NeedsGuide = lazyWithRetry(
  () => import("./components/NeedsGuide/NeedsGuide")
);
const ViewProduct = lazyWithRetry(
  () => import("./components/Products/ViewProduct")
);
const NotFound = lazyWithRetry(() => import("./components/Layout/NotFound"));
const Notifications = lazyWithRetry(
  () => import("./components/Notifications/Notifications")
);
const FreeTrial = lazyWithRetry(() => import("./components/Store/FreeTrial"));
const CompanyRegister = lazyWithRetry(
  () => import("./components/Store/CompanyRegister")
);
const Onboarding = lazyWithRetry(() => import("./components/Home/Onboarding"));
const UlStandards = lazyWithRetry(
  () => import("./components/Tools/UlStandards/UlStandards")
);
const GlobalMarkings = lazyWithRetry(
  () => import("./components/Tools/GlobalMarkings/GlobalMarkings")
);
const Monitoring = lazyWithRetry(
  () => import("./components/Admin/Monitoring/Monitoring")
);
const NewsList = lazyWithRetry(() => import("./components/News/NewsList"));
const AcceptInvitation = lazyWithRetry(
  () => import("./components/Admin/AcceptInvitation")
);
const AdminView = lazyWithRetry(() => import("./components/Admin/AdminView"));
const Tasks = lazyWithRetry(() => import("./components/Tasks/Overview"));
const Details = lazyWithRetry(() => import("./components/Products/Details"));

//Production
const tagManagerArgs = {
  gtmId: "GTM-KLWGX3D",
  auth: "841ZdMezl5GVmzRC_vyQ4w",
  preview: "env-2",
};

//Development
if (process.env.REACT_APP_ENVIRONMENT === "development") {
  tagManagerArgs.auth = "MJjSwhrzo3aAy1mnPm_Rxg";
  tagManagerArgs.preview = "env-5";
} else if (process.env.REACT_APP_ENVIRONMENT === "staging") {
  tagManagerArgs.auth = "eR-5mRhpQ-ndtxlAHcqyGQ";
  tagManagerArgs.preview = "env-7";
} else {
  //Production
  Sentry.init({
    dsn: "https://67cb3320f1eb46798a9a83b1315d2be5@o275291.ingest.sentry.io/5596639",
    autoSessionTracking: true,
  });
}

TagManager.initialize(tagManagerArgs);

function FallbackComponent() {
  return <div>An error has occurred</div>;
}

const myFallback = <FallbackComponent />;

const App = function () {
  const [showModal, setShowModal] = useState(false);
  const [loadedSections, setLoadedSections] = useState(0);
  const closeModal = () => {
    setShowModal(false);
  };
  function setLoaded() {
    setLoadedSections((a) => a + 1);
  }
  return (
    <Sentry.ErrorBoundary fallback={myFallback}>
      <BrowserRouter>
        <RootStore>
          <AuthProvider>
            <LicenseProvider>
              <AuthorizationProvider>
                <div className="layout-fixed">
                  <div className="wrapper">

                    <Header />
                    <Sidebar />
                    <Suspense
                      fallback={
                        <LoadingWrapper
                          loaders={[
                            { isLoading: true, text: "Loading page..." },
                          ]}
                        >
                          Loading...
                        </LoadingWrapper>
                      }
                    >
                      <Routes>
                        <Route path="/" element={<Dashboard />} />
                        <Route
                          path="/sharedfile/:id/:hash"
                          element={<SharedFile />}
                        />
                        <Route
                          path="/onboarding"
                          element={
                            <AuthenticatedRoute>
                              <Onboarding />
                            </AuthenticatedRoute>
                          }
                        />
                        <Route
                          path="/store/free-trial"
                          element={<FreeTrial />}
                        />
                        <Route
                          path="/store/company-register"
                          element={<CompanyRegister />}
                        />
                        <Route path="/store" element={<Store />} />
                        <Route path="/store/:category?" element={<Store />} />
                        <Route path="/needs-guide" element={<NeedsGuide />} />
                        <Route path="/tools" element={<Tools />} />
                        <Route
                          path="/account/signin-oidc"
                          element={<SignInCallback />}
                        />
                        <Route
                          path="/account/signout-oidc"
                          element={<SignOutCallback />}
                        />
                        <Route
                          path="/account/silent-renew"
                          element={<SignInSilentCallback />}
                        />
                        <Route
                          path="/tools/legislation-finder"
                          element={<LegislationFinder />}
                        />
                        <Route
                          path="/tools/harmonised-standards"
                          element={<HarmonisedStandards />}
                        />
                        <Route
                          path="/tools/ul-standards"
                          element={<UlStandards />}
                        />
                        <Route
                          path="/tools/documentation-requirements"
                          element={<DocumentationRequirement />}
                        />
                        <Route
                          path="/tools/notified-bodies"
                          element={<NotifiedBodies />}
                        />
                        <Route
                          path="/tools/documentation-manager"
                          element={<DocumentationManager />}
                        />
                        <Route
                          path="/tools/documentation-generator"
                          element={<DocumentationGenerator />}
                        />
                        <Route
                          path="/tools/standard-finder"
                          element={
                            <GlobalStandardFinder onLoaded={setLoaded} />
                          }
                        />
                        <Route
                          path="/tools/global-legislation-finder"
                          element={
                            <GlobalLegislationFinder onLoaded={setLoaded} />
                          }
                        />
                        <Route
                          path="/tools/global-markings"
                          element={<GlobalMarkings />}
                        />
                        <Route
                          path="/notifications"
                          element={<Notifications />}
                        />
                        <Route path="/products" element={<Products />} />
                        <Route path="/products/:id" element={<ViewProduct />} />
                        <Route
                          path="/products/:id/:overview"
                          element={<ViewProduct />}
                        />
                        <Route
                          path="/products/:id/:details"
                          element={<Details />}
                        />

                        <Route path="/tasks" element={<Tasks />} />

                        <Route
                          path="/admin/monitoring"
                          element={<Monitoring />}
                        />

                        <Route path="/news" element={<NewsList />} />

                        <Route
                          path="/user/invitation/:token"
                          element={<AcceptInvitation />}
                        />
                        <Route
                          path="/tools/documentation-manager/:tab?/:productId?"
                          element={<DocumentationManager />}
                        />
                        <Route path="/admin/*" element={<AdminView />} />
                        <Route element={<NotFound />} />
                      </Routes>
                    </Suspense>
                    <Footer />
                    <button className="fixed-button"
                      onClick={() => {
                        setShowModal(true)
                      }}
                    >
                      Feedback
                    </button>
                    <CreateFeedback showModal={showModal} closeModal={closeModal} />
                  </div>
                </div>
              </AuthorizationProvider>
            </LicenseProvider>
          </AuthProvider>
        </RootStore>
      </BrowserRouter>
    </Sentry.ErrorBoundary>
  );
};

export default App;
