import { makeAutoObservable, runInAction } from "mobx"
import { Unwrap } from "../utils/api/apiClient";
import { RootStore } from "./RootStore";
import { Country } from "../models/Country";
import BlockHelper from "./BlockHelper"
import { EuLegalAct } from "../models/EuLegalAct";
import { CustomLegalAct } from "../models/CustomLegalAct";
import _ from "lodash";
import { AxiosResponse } from "axios";
import { LegalAct } from "../models/LegalAct";


export default class SharedDataStore {
    rootStore : RootStore;
    private _countries: Country[] | null = null;
    private _euLegalActs: EuLegalAct[] | null = null;
    private _customLegalActs: CustomLegalAct[] | null = null;
    private _blockHelper = new BlockHelper();
    private _customGlobalMarkting: CustomLegalAct[] | null = null;
    private _acts: LegalAct[] | null = null;

    constructor(rootStore : RootStore) {
        makeAutoObservable(this, { rootStore: false, _blockHelper: false, getCountries: false, getLegalActs: false } as any);
        this.rootStore = rootStore;
    }

    get countries() {
        this.getCountries();
        return this._countries;
    }

    get legalActs() {
        this.getLegalActs();
        if (this._euLegalActs == null || this._customLegalActs == null) return null;
        return _.sortBy([...this._euLegalActs ?? [], ...this._customLegalActs ?? []], "name");
    }

    get acts() {
        this.getLegalActs();
        // if (this._euLegalActs == null || this._customLegalActs == null) return null;
        // return _.sortBy([...this._euLegalActs ?? [], ...this._customLegalActs ?? []], "name");
        return this._acts;
    }
   
    
    get euLegalActs() {
        this.getLegalActs();
        return this._euLegalActs;
    }

    get customLegalActs() {
        this.getLegalActs();
        return this._customLegalActs;
    }

    private async getCountries() {
        if (this._countries != null || !this._blockHelper.tryBlock("countries")) return;

        let res = await this.rootStore.apiClient.get("countries");
        let countries = Unwrap.result<Country[]>(res);
        runInAction(() => {
            this._countries = countries ?? [];
            this._blockHelper.unblock("countries");
        });
    }
    private async getLegalActs() {
        if ((this._euLegalActs != null && this._customLegalActs != null) || !this._blockHelper.tryBlock("legalacts")) return;
        let res = await this.rootStore.apiClient.get("legalacts");
        //let acts = Unwrap.result<LegalAct[]>(res);

        let promises : Promise<(AxiosResponse<any> | null)>[] = [this.rootStore.apiClient.get("legalacts/eu")];
        //If we have permissions to read custom legal acts, then we try to fetch them. Otherwise, resolve the promise with the result of null

        if (this.rootStore.sessionStore.license?.userPermissions.customLegislations.read === true) promises.push(this.rootStore.apiClient.get("legalacts/custom"));
        else promises.push(Promise.resolve(null));

        const [euRes, customRes] = await Promise.all(promises);

        //If we have a result, we unwrap it. Otherwise, it's null and we coalesce it to an empty array (this is to avoid a refetch, as the if statement in the top checks if the results are null to avoid fetchning data for infinity)
        const euLegalActs = !!euRes ? Unwrap.result<EuLegalAct[]>(euRes) : [];
        const customActs = !!customRes ? Unwrap.result<CustomLegalAct[]>(customRes) : [];
        const acts = !!res ? Unwrap.result<LegalAct[]>(res) : [];


        runInAction(() => {
            this._euLegalActs = euLegalActs;
            this._customLegalActs = customActs;
            this._acts = acts;
            this._blockHelper.unblock("legalacts");
        });
    }

    
    clear() {
        this._countries = [];
        this._euLegalActs = [];
        this._customLegalActs = [];
        this._blockHelper.clear();
        this._customGlobalMarkting=[];
    }

}