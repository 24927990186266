import React, { useState, useEffect, useCallback } from "react";
import { ApiClient, Unwrap } from "../../utils/api/apiClient";
import { LoginUserPermissions } from "../../models/LoginUserPermissions";
import useRootStore from "../../hooks/rootStoreHook";
import { observer } from "mobx-react-lite";
export const PermissionsContext = React.createContext<any>(null);
export const PermissionsConsumer = PermissionsContext.Consumer;

export const AuthorizationProvider = observer((props: any) => {

    const { sessionStore } = useRootStore();
    const accessToken = sessionStore.user?.access_token;
    const apiClient = useCallback(ApiClient.Create({ accessToken }), [accessToken]);
    const [loginUserPerms, setLoginUserPerms] = useState<LoginUserPermissions[]>([]);
    const [userPerm, setUserPerm] = useState<{ users: boolean, products: boolean, customLegislations: boolean }>({ users: true, products: true, customLegislations: true });
    const actions = {
        updateUserPermissions() {
            apiClient.get("User/Permissions").then((res) => {
                let permissions: any = {};
                let response = Unwrap.result(res);
                setLoginUserPerms(response);
                permissions['users'] = Object.values(response.users).every(v => v === false); // to check all values is false
                permissions['customLegislations'] = Object.values(response.customLegislations).every(v => v === false)
                permissions['products'] = Object.values(response.products).every(v => v === false)
                setUserPerm(permissions);
            });
        }
    }
    useEffect(() => {
        if (sessionStore.authenticated && sessionStore.license != null) {
            apiClient.get("User/Permissions").then((res) => {
                let permissions: any = {};
                let response = Unwrap.result(res);
                setLoginUserPerms(response);
                permissions['users'] = Object.values(response.users).every(v => v === false); // to check all values is false
                permissions['customLegislations'] = Object.values(response.customLegislations).every(v => v === false)
                permissions['products'] = Object.values(response.products).every(v => v === false)
                setUserPerm(permissions);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [apiClient, sessionStore.license, sessionStore.authenticated])

    return <PermissionsContext.Provider value={{ state: { loginUserPerms, userPerm }, actions }}>{props.children}</PermissionsContext.Provider>
})