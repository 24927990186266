import React from "react";
import { Route, RouteProps, Outlet, Routes } from "react-router-dom";
import Wrapper from "../Wrapper";
import { AuthConsumer } from "./AuthProvider";
import Unauthenticated from "./Unauthenticated";

export class AuthenticatedRoute extends React.Component<RouteProps> {

    render() {
        // let props = {...this.props, ...{component: this.props.component }};

        let { element, ...rest } = this.props;

        return <AuthConsumer>
            {
                context => (
                    <Routes>
                        <Route children={
                            context!.state.authenticated ? <Outlet /> : <Wrapper><Unauthenticated></Unauthenticated></Wrapper>
                        } />
                    </Routes>
                )
            }
        </AuthConsumer>
    }
}