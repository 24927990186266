import axios, { AxiosResponse, AxiosRequestConfig, AxiosInstance } from "axios";
import { ApiResponse } from "../../models/ApiResponse";
import _ from "lodash"
import authService from "../AuthService";
import { alert } from "../../components/Helper_component/Swal";
import config from "../config";
import accessDenied from "../../images/illustrations/access-denied.svg";
import warning from "../../images/illustrations/warning.svg";

export interface StatusHandler {
    statusCode: number | null, //Null is default handler
    handler: (error: any) => {};
}

export interface Config {
    registerInterceptors?: { response: boolean, request: boolean },
    transformData?: boolean,
    resource?: string | undefined,
    accessToken?: string | undefined,
    axiosConfig?: AxiosRequestConfig,
    statusHandlers?: StatusHandler[]
}
export class DefaultConfig implements Config {
    registerInterceptors = { request: true, response: true };
    transformData = true;
    resource = window.location.host;
    statusHandlers = [
        {
            statusCode: 401,
            handler: (error) => {
                authService.promptSignIn();
            }
        } as StatusHandler,
        {
            statusCode: 403,
            handler: (error) => {
                let message = undefined as string | undefined;
                if (error.response && error.response.data && Array.isArray(error.response.data.messages)) {
                    //For HTML encoding error messages
                    const dummyDiv = document.createElement("div");
                    message = "";
                    error.response.data.messages.forEach((errorMsg) => {
                        //By setting the innerText and then retrieving the innerHTLM, we get HTML encoded text back
                        dummyDiv.innerText = errorMsg;
                        message += `<div class='alert alert-danger my-1'>${dummyDiv.innerHTML}</div>`;
                    });
                }
                alert.fire({
                    title: "Access denied",
                    imageUrl: accessDenied,
                    imageHeight: 150,
                    html: message
                }
                )
            }
        } as StatusHandler,
        //Default
        {
            statusCode: null,
            handler: (error) => {
                console.error(error);
                let message = "Unfortunately, it seems our server disagrees with us. We're going to speak with it, sorry for the issues.";
                //There are messages which should be displayed to the user
                if (error.response && error.response.data && Array.isArray(error.response.data.messages)) {
                    //For HTML encoding error messages
                    const dummyDiv = document.createElement("div");
                    message += "<div class='mt-2'>Details</div>"
                    error.response.data.messages.forEach((errorMsg) => {
                        //By setting the innerText and then retrieving the innerHTLM, we get HTML encoded text back
                        dummyDiv.innerText = errorMsg;
                        message += `<div class='alert alert-danger my-1'>${dummyDiv.innerHTML}</div>`;
                    });
                }
                alert.fire({
                    title: "Something went wrong",
                    imageUrl: warning,
                    imageHeight: 150,
                    html: message
                }
                )
            }
        } as StatusHandler
    ];
    axiosConfig = {
        baseURL: config.apiUri
    } as AxiosRequestConfig;
}

export class Unwrap {
    static response<T = any>(response: AxiosResponse<T>): ApiResponse<T> {
        if (!response) throw new Error("Can't unwrap data because the response data is empty from the server")
        return response.data as unknown as ApiResponse<T>;
    }
    static result<T = any>(response: AxiosResponse<T>): T | null {        
        var res =  Unwrap.response(response).result;
        return res;
    }
}

export class ApiClient {
    static Create(config?: Config): AxiosInstance {
        let options = new DefaultConfig() as Config;
        if (config) options = _.merge(options, config ?? {});
        const instance = axios.create(options.axiosConfig);
        if (options.registerInterceptors?.request !== false) {
            instance.interceptors.request.use(async function (config) {
                if (!config?.headers) {
                    throw new Error(`Expected 'config' and 'config.headers' not to be undefined`);
                }
                else if (config.headers.common) {
                    config.headers["resource"] = <any>options.resource;
                    if (!!options.accessToken) {
                        config.headers.Authorization = `Bearer ${options.accessToken}`;
                    }
                }
                else delete config.headers.Authorization;

                return config;
            });
        }
        if (options.registerInterceptors?.response !== false) {
            instance.interceptors.response.use((response) => {
                return Promise.resolve(response);
            }, (error) => {
                //Received a response, trigger a status handler
                if (error.response) {
                    let handlers = options.statusHandlers?.filter(a => a.statusCode === error.response.status || a.statusCode === null);
                    if (!handlers) return;
                    //Only one valid found, trigger it 
                    if (handlers.length === 1) handlers[0].handler(error);
                    //Multiple valid found, let's only trigger non-null (null = default handler)
                    else if (handlers.length > 1) {
                        handlers.forEach(handler => {
                            if (handler.statusCode == null) return;
                            handler.handler(error);
                        });
                    }
                }
                else options.statusHandlers?.find(a => a.statusCode === null)?.handler(error);
                return Promise.reject(error);
            })
        }
        return instance;
    }
}
export default ApiClient.Create();