interface IFilterParam {
    Operator : string,
    Property : string,
    Value : string
}

enum Operator {
    Equals = "==",
    NotEquals = "!=",
    GreaterThan = ">",
    LessThan = "<",
    GreaterThanOrEqual = ">=",
    LessThanOrEqual = "<=",
    Contains = "@=",
    StartsWith = "_=",
    DoesNotContain = "!@=",
    DoesNotStartWith = "!_=",
}

export interface IQueryFilterBuilder {
    Property(name : string) : IFilterParamBuilder;
    Build() : string;
    ToQueryParam() : string;
}
export interface IFilterParamBuilder {
    Equals(filter : string | number) : ITextFilterParamBuilder;
    NotEquals(filter : string | number) : ITextFilterParamBuilder;
    GreaterThan(filter : number) : IQueryFilterBuilder;
    GreaterThanOrEqual(filter : number) : IQueryFilterBuilder;
    LessThan(filter : number) : IQueryFilterBuilder;
    LessThanOrEqual(filter : number) : IQueryFilterBuilder;
    StartsWith(filter : string) : ITextFilterParamBuilder;
    Contains(filter : string) : ITextFilterParamBuilder;
}
export interface ITextFilterParamBuilder {
    IsCaseSensitive() : IQueryFilterBuilder;
    NotCaseSensitive() : IQueryFilterBuilder;
}

export default class QueryFilterBuilder implements IQueryFilterBuilder, IFilterParamBuilder, ITextFilterParamBuilder {
    private filters : IFilterParam[] = [];
    private isCaseSensitive = false;
    private propertyName : string | null = null;
    private value : string | number | null = null;
    private operator : Operator | null = null;
    
    private constructor() {

    }

    static Create() : IQueryFilterBuilder {
        return new QueryFilterBuilder();
    }

    private AddFilter() {
        let param = this.ToFilterParam();
        this.filters.push(param);
        this.isCaseSensitive = false;
        this.propertyName = null;
        this.value = null;
        this.operator = null;
    }

    ToFilterParam() : IFilterParam {
        if (this.propertyName == null) throw new Error("Property is null");
        if (this.value == null) throw new Error("Value is null");
        if (this.operator == null) throw new Error("Operator is null");
        return { Property: this.propertyName, Value: this.value.toString(), Operator: this.isCaseSensitive ? this.operator : `${this.operator}*` }
    }

    private GetQueryParam(param : IFilterParam) {
        return `${param.Property}${param.Operator}${param.Value}`;
    }
    ToQueryParam() {
        return this.GetQueryParam(this.filters[this.filters.length-1]);
    }

    Property(name : string) : IFilterParamBuilder {
        this.propertyName = name;
        return this;
    }

    IsCaseSensitive() : IQueryFilterBuilder {
        this.isCaseSensitive = true;
        this.AddFilter();
        return this;
    }
    NotCaseSensitive() : IQueryFilterBuilder {
        this.isCaseSensitive = false;
        this.AddFilter();
        return this;
    }

    Equals(value : string | number) : ITextFilterParamBuilder {
        this.value = value;
        this.operator = Operator.Equals;
        return this;
    }
    NotEquals(value : string | number) : ITextFilterParamBuilder {
        this.value = value;
        this.operator = Operator.NotEquals;
        return this;
    }
    GreaterThan(value : number) : IQueryFilterBuilder {
        this.value = value;
        this.operator = Operator.GreaterThan;
        return this.IsCaseSensitive(); //Ugly but ensures it doesn't add * at the end of the filter operator and then adds the filter 
    }
    GreaterThanOrEqual(value : number) : IQueryFilterBuilder {
        this.value = value;
        this.operator = Operator.GreaterThanOrEqual;
        return this.IsCaseSensitive(); //Ugly but ensures it doesn't add * at the end of the filter operator and then adds the filter 
    }
    LessThan(value : number) : IQueryFilterBuilder {
        this.value = value;
        this.operator = Operator.LessThan;
        return this.IsCaseSensitive(); //Ugly but ensures it doesn't add * at the end of the filter operator and then adds the filter 
    }
    LessThanOrEqual(value : number) : IQueryFilterBuilder {
        this.value = value;
        this.operator = Operator.LessThanOrEqual;
        return this.IsCaseSensitive(); //Ugly but ensures it doesn't add * at the end of the filter operator and then adds the filter 
    }
    StartsWith(value : string) : ITextFilterParamBuilder {
        this.value = value;
        this.operator = Operator.StartsWith;
        return this;
    }
    Contains(value : string) : ITextFilterParamBuilder {
        this.value = value;
        this.operator = Operator.Contains;
        return this;
    }

    Build() : string {
        return encodeURIComponent(this.filters.map(this.GetQueryParam).join(","));
    }
}