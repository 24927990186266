import localforage from "localforage";
var config = {
  localForage: localforage.createInstance({
    name: "clever"
  }),
  signinUrl:
    //"https://identity.staging.clevercompliance.io/signin?ReturnUrl=%2Fconnect%2Fauthorize%2Fcallback%3Fclient_id%3DComplianceHub%26redirect_uri%3Dhttps%253A%252F%252Fce-check-web.cortexcraft.com%252Faccount%252Fsignin-oidc%26response_type%3Did_token%2520token%26scope%3Dopenid%2520profile%2520ComplianceHub.InternalApi.V1%26state%3D58c957f19f5f4c0eac45d8704b195f05%26nonce%3D5ba81a20f990415da3670a10ad9321a0%26display%3Dpopup",

    "https://identity.staging.clevercompliance.io/signin?ReturnUrl=%2Fconnect%2Fauthorize%2Fcallback%3Fclient_id%3DComplianceHub%26redirect_uri%3Dhttp%253A%252F%252Fsub1.localhost.internal%253A9090%252Faccount%252Fsignin-oidc%26response_type%3Did_token%2520token%26scope%3Dopenid%2520profile%2520ComplianceHub.InternalApi.V1%26state%3Dc9b52c3ab5f4441197c28190bc7594e9%26nonce%3D63f19694e72449e9a56e2085b4a05d9a%26display%3Dpopup",

  signoutUrl:
    "https://hub.staging.clevercompliance.io/signout?logoutId=CfDJ8EEFeNRbK0JPgtTIPB9hns0SuzgBSLps97X9WzYyYbm3ZEY-EThk7fyzJi7VE0DvzRbIXG1GNhHZ2v6EoWQHZr0XhLSii0IkDxheTNsvvwnGzwwSY1uImCYP58s7XVhFqSHaWx1guIYQzwrto-msNqnsRI_StSNj6CXqOugPY0MBPVJyDmhRgdjU9JuS4Mv3mw35ajhtWsSFdEQD4qF4SyVa6nAaM8IhoGpYuqqMsRejL1w-ZsAT5HNC9IiOb3uYocj5cYGY_ixAsIAOtlUnfQomfEVei7UOqmExJQW8oKbzhPg7uuV9WW8ssCX5lzFRiUQUw5vVbYb43unjo3VO9BfQ4TwGsa7i8_M3nRfoJETAETUw7_C0fbiXm6Nj6RZTNw-SoBskvAuE38RrqkH7Fk179xRUIXRePInDtumgBHUGcS_KgUllvYEw7FZt5dADTI296D161M46Py-u6XbzmsGqMqlrW8I-b9GT_oPW3Q0vV5l5B63SexayE2OfcZHcUvd45993s5O467Kxx46AHDHT6HzkSLFOYDhso4zhYPOm3C_t2_jwwdzjxVJGHOS1Zyf8ol3JSVPHLyq1k8Bjprw",

  legislationURL:
    "https://hub.staging.clevercompliance.io/api/tools/legislationfinder",

  analyseURL:
    "https://hub.staging.clevercompliance.io/api/tools/legislationfinder/analyse",

  smartPrediction:
    "https://hub.staging.clevercompliance.io/api/tools/legislationfinder/analyse",

  documentationRequirementURL:
    "https://hub.staging.clevercompliance.io/api/tools/DocumentationRequirements/Documents/",

  selectLegislation:
    "https://hub.staging.clevercompliance.io/api/tools/DocumentationRequirements/LegislationSuggestion/",
  
  REACT_APP_AUTH_URL: process.env.REACT_APP_AUTHORITY,
  apiUri: process.env.REACT_APP_API_URI,
  REACT_APP_IDENTITY_CLIENT_ID: "ComplianceHub",
  REACT_APP_REDIRECT_URL: window.location.origin + "/account/signin-oidc",
  REACT_APP_SILENT_REDIRECT_URL:
    window.location.origin + "/account/silent-renew",
  REACT_APP_LOGOFF_REDIRECT_URL:
    window.location.origin + "/account/signout-oidc",
    supportEmail: "support@clevercompliance.io",
    generalEmail: "contact@clevercompliance.io",
    
};
if (!config.apiUri?.endsWith("/")) config.apiUri = `${config.apiUri}/`;
export default config;
