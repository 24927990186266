import ProductsStore from "./ProductsStore";
import SessionStore from "./SessionStore";
import { reaction } from "mobx";
import SharedDataStore from "./SharedDataStore";

export class RootStore {
    sessionStore : SessionStore;
    productsStore : ProductsStore;
    sharedDataStore : SharedDataStore;
    constructor() {
        this.sessionStore = new SessionStore(this);
        this.productsStore = new ProductsStore(this);
        this.sharedDataStore = new SharedDataStore(this);
    }

    /**
     * Clear all data
     */
    clear() {
        this.productsStore.clear();
        this.sessionStore.clear();
        this.sharedDataStore.clear();
    }

    /** 
     * Convinience method for sessionStore.apiClient
     **/
    get apiClient() {
        return this.sessionStore.apiClient;
    }
}

const RootStoreInstance = new RootStore();

reaction(() => {
    return { 
        userId: RootStoreInstance.sessionStore.user?.profile?.sub,
        spaceId: RootStoreInstance.sessionStore.currentSpace?.id
    }
}, ({userId}) => {
    RootStoreInstance.productsStore.clear();
    if (!userId) RootStoreInstance.sessionStore.clear();
}, { delay: 500 });

export default RootStoreInstance;