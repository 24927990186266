import * as React from "react";
// import "../../styles/header.css"
import illustrations_update_img from "../../images/illustrations/update.svg";
import CheckIcon from '@material-ui/icons/Check';
import {alert, toast} from "../../components/Helper_component/Swal";
import TagManager from 'react-gtm-module'
import useRootStore from "../../hooks/rootStoreHook";

function ContactMe(props) {
   const checkLabels = ["Alerts & monitoring", "Education",
      "Workflow improvements", "Reporting"];
 
   const [email, setEmail] = React.useState<string | undefined>();
   const [name, setName] = React.useState<string | undefined>();

   const { apiClient } = useRootStore();

   const submitForm = async (e : React.FormEvent) => {
      e.preventDefault();
      let obj = { name, email };
            if (!obj.name || !obj.email) {
                alert.fire("Missing information", "Please enter both name and email");
                return;
            }
           
            toast.fire({
                title: "Sending...",
                timer: undefined,
                icon: undefined
            });
            await apiClient.post("store/request/upgrade", obj);
            TagManager.dataLayer({
               dataLayer: {
                  event: 'upgrade-requested',
                  label: 'Upgrade requested',
               }
            });
            alert.fire("Received!", "We're excited to get in touch as soon as possible.", "success");
            if (!!props.submitted) props.submitted(obj);
   }

   return (
      <div className="container">
         <div id="upgrade-form">
            <div className="card b">

               <div className="card-body">

                  <div className="row align-items-center">
                     <div className="col-5">
                        <img src={illustrations_update_img} alt="" className="img-fluid" />
                     </div>
                     <div className="text-center col">
                        <h2 className="mb-4">Yes, I want easy product compliance</h2>
                        Our mission has always been to make product compliance easy<br /> Now, with the Compliance Hub, you can get:
                       <ul className="list-inline font-weight-bold">
                           {checkLabels.map(label =>
                              <li className="d-inline-flex list-inline-item" key={label}>
                                 <CheckIcon fontSize="small" />
                                 <title>Check icon</title>
                                 {label}
                              </li>
                           )}
                        </ul>

                        <form className="" onSubmit={submitForm}>
                           <div role="group" className="form-group" id="__BVID__71">

                              <div className="bv-no-focus-ring">
                                 {/* <input id="name" type="text" placeholder="Your name" autocomplete="name" className="form-control form-control-lg" /> */}
                                 <input id="name" type="text" placeholder="Your name" className="form-control form-control-lg"  onChange={(e:React.ChangeEvent<HTMLInputElement>) => setName(e.currentTarget.value)} />

                              </div>
                           </div>
                           <div role="group" className="form-group" id="__BVID__73">

                              <div className="bv-no-focus-ring">
                                 {/* <input id="email" type="email" placeholder="Email" autocomplete="email" className="form-control form-control-lg" /> */}
                                 <input id="email" type="email" placeholder="Email" onChange={(e:React.ChangeEvent<HTMLInputElement>) => setEmail(e.currentTarget.value)} className="form-control form-control-lg" />
                              </div>
                           </div>
                           <button type="submit" className="btn btn-oval btn-purple btn-lg">Contact me</button>
                        </form>
                     </div>
                  </div>
               </div>

            </div>
            <div className="bg-primary-dark p-3 rounded">
               <h2 className="m-0 text-center"> Features included </h2>
               <div className="text-center">
                  <div className="mt-4 d-inline-block text-left">
                     <div className="row">
                        <div className="col-auto">
                           <div className="features">
                              <div className="features-icon features-left">
                                 <CheckIcon />
                                 <title>Check icon</title>
                   </div>
                              <div className="features-desc">
                                 <h3 className="features-title text-white">Alerts &amp; monitoring</h3>
                              </div>
                           </div>
                           <div className="features mt-3">
                              <div className="features-icon features-left">
                                 <CheckIcon />
                              </div>
                              <div className="features-desc">
                                 <h3 className="features-title text-white">Workflow</h3>
                              </div>
                           </div>
                        </div>
                        <div className="col-auto">
                           <div className="features">
                              <div className="features-icon features-left ">
                                 <CheckIcon />
                              </div>
                              <div className="features-desc">
                                 <h3 className="features-title text-white">Collaborate</h3>
                              </div>
                           </div>
                           <div className="features mt-3">
                              <div className="features-icon features-left">
                                 <CheckIcon />
                              </div>
                              <div className="features-desc">
                                 <h3 className="text-white features-title">Reporting</h3>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>

   );
}

export default ContactMe