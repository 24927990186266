import { makeAutoObservable, runInAction } from "mobx"
import { User } from "oidc-client";
import { License } from "../models/License";
import { Space, Spaces } from "../models/Spaces";
import { ApiClient, Unwrap } from "../utils/api/apiClient";
import authService from "../utils/AuthService";
import { RootStore } from "./RootStore";
import { spaceResolver } from "../utils/spaceHelper";
import BlockHelper from "./BlockHelper";

export default class SessionStore {
    user: User | null = null;
    private _license: License | null = null;
    private _spaces: Spaces | null = null;
    private _currentSpace: Space | null = null;
    loading: boolean = false;
    private _disableLicenseLoading = false;
    private _blockHelper = new BlockHelper();

    //Root store added as param to ensure all stores have the same signature, regardless if they need it or not. Could be needed in the future.
    constructor(_: RootStore) {
        makeAutoObservable(this, { loadCurrentLicense: false, _disableLicenseLoading: false } as any);
        authService.onUserSet((a: User) => this.setUser(a));

        authService.getUser().then(foundUser => {
            if (foundUser) this.setUser(foundUser);
        });
    }

    clear() {
        this._currentSpace = null;
        this._spaces = null;
        this._license = null;
        this.user = null;
        this._blockHelper.clear();
    }

    private setUser(user: User) {
        this.user = user;
        if (!user == null) this.clear();
    }

    get authenticated() {
        return !!this.user && !this.user.expired;
    }

    get hasSpaces() {
        return !!this._spaces;
    }

    get apiClient() {
        let accessToken = this.user?.access_token;
        let resource = this.currentSpace?.domain;
        return ApiClient.Create({ accessToken, resource })
    }

    addUserSpace(space: Space) {
        if (this._spaces == null) throw Error("Spaces is null");
        this._spaces.userSpaces.push(space);
    }

    disableLicenseLoading(val: boolean) {
        this._disableLicenseLoading = val;
    }

    setCurrentSpace(space: Space | null) {
        this._currentSpace = space;
    }

    private ifLoaded<T>(prop: T): null | T {
        return this.loading ? null : prop;
    }

    get license(): License | null {
        this.loadCurrentLicense();
        return this.ifLoaded(this._license);
    }
    get spaces(): Spaces | null {
        this.loadCurrentLicense();
        return this.ifLoaded(this._spaces);
    }
    get currentSpace(): Space | null {
        return this.ifLoaded(this._currentSpace);
    }

    private tryBlock() {
        return this._blockHelper.tryBlock("license");
    }
    private unblock() {
        return this._blockHelper.unblock("license");
    }

    async loadCurrentLicense(bypassCache = false): Promise<void> {
        if (this._disableLicenseLoading || !this.tryBlock())
            return;

        if (!this.authenticated) {
            runInAction(() => {
                this._license = null;
                this._spaces = null;
                this._currentSpace = null;
                this.unblock();
            });
            return;
        }
        if (bypassCache !== true && this._license !== null) {
            runInAction(() => {
                this.unblock();
            });
            return;
        }
        //This means we found spaces but we're not in one, hence the empty license
        if (this._spaces != null && this._license == null) {
            return;
        }
        runInAction(() => {
            this.loading = true;
        });
        let licenseTask = await this.apiClient.get("licenses/current");
        let spacesTask = await this.apiClient.get("spaces");
        let results = await Promise.all([licenseTask, spacesTask]);
        let license = Unwrap.result<License | null>(results[0]);
        let spaces = Unwrap.result<Spaces | null>(results[1])
        let self = this;

        runInAction(() => {
            self._license = license;
            self._spaces = spaces;
            self._currentSpace = !!spaces ? (spaceResolver(spaces?.userSpaces) ?? spaceResolver(spaces?.collaborationSpaces)) : null;
            self.loading = false;
            this.unblock();
        });
    }
    signIn() {
        authService.signIn();
    }
    register() {
        authService.startRegistration();
    }
    signOut() {
        this._license = null;
        this.setCurrentSpace(null);
        this.user = null;
        authService.signOut();
    }
    promptSignIn(opts = {}) {
        authService.promptSignIn(opts)
    }
}