const scriptId = "product-feed-script";

export function registerProductFeedWidget() {
    (function(b,a,r,e,l,o,g){
        // eslint-disable-next-line no-unused-expressions
        o=a.createElement(r);
        g=a.getElementsByTagName(r)[0];
        o.async=1;o.src=e;g.parentNode.insertBefore(o,g);
        o.id = scriptId;
        // eslint-disable-next-line no-undef
        o.addEventListener('load', function(){setTimeout(function(){Barelog(options) }, 50)});
        })(window,document,'script','https://cdn.productfeed.app/widget.js');
        
        var options = {
        id: "vw0vprgzt8st",
        trigger: "#productfeed-trigger"
    }
}

export function unregisterProductFeedWidget() {
    let script = document.getElementById(scriptId);
    if (!!script && script.tagName === "script") {
        document.removeChild(script);
    }
}