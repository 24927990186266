import { makeAutoObservable, runInAction } from "mobx"
import { Product } from "../models/Product";
import { Unwrap } from "../utils/api/apiClient";
import { RootStore } from "./RootStore";
import BlockHelper from "./BlockHelper";

export class NotFoundProduct {
    id: string;
    constructor(id: string) {
        this.id = id;
    }
}

export default class ProductsStore {
    rootStore: RootStore;
    private _products: (Product | NotFoundProduct)[] = [];
    private _blockHelper = new BlockHelper();
    lastViewedProducts: { id: string, name: string }[] = [];

    constructor(rootStore: RootStore) {
        makeAutoObservable(this, { rootStore: false } as any);
        this.rootStore = rootStore;
    }

    isProduct(p: Product | NotFoundProduct | null | undefined): p is Product {
        return (p as Product)?.name !== undefined;
    }
    isNotFoundProduct(p: Product | NotFoundProduct | null | undefined): p is NotFoundProduct {
        if (p === null || p === undefined) return false;
        return (p as Product)?.name === undefined;
    }

    /**
     * Check if the param is of Product type, not anything else (NotFoundProduct or falsey). 
     * @param p A potential product
     * @returns A product if it's a Product type, otherwise null
     */
    ifProduct(p: Product | NotFoundProduct | null | undefined) {
        return this.isProduct(p) ? p : null;
    }

    cacheProduct(product: Product | NotFoundProduct, overwrite = true) {
        let found = this._products.findIndex(a => a.id === product.id);
        if (!overwrite && found > -1) return;

        if (this.isProduct(product)) {
            runInAction(() => {
                this.pushLastViewedProduct(product);
            });
        }

        if (found > -1) this._products[found] = product;
        else this._products.push(product);
    }

    clear() {
        this._products = [];
        this.lastViewedProducts = [];
        this._blockHelper.clear();
    }

    async patchProduct(id: string, changes: object) {
        this._blockHelper.tryBlock(id); //Note that we don't care about if it's already blocked, we will patch this regardless. But if we can prevent some fetches while we put it, that's at least something.
        const res = Unwrap.result(await this.rootStore.apiClient.patch(`products/${id}`, changes));
        runInAction(() => {
            this.cacheProduct(res);
        });
    }

    async putProduct(product: Product) {
        this._blockHelper.tryBlock(product.id); //Note that we don't care about if it's already blocked, we will put this regardless. But if we can prevent some fetches while we put it, that's at least something.
        await this.rootStore.apiClient.put("products", product);
        runInAction(() => {
            this.cacheProduct(product);
        });
    }

    removeCachedProduct(id: string | undefined) {
        this._products = this._products.filter(a => a.id !== id);
    }

    private async fetchProduct(id: string) {
        if (!this._blockHelper.tryBlock(id)) return;
        let res = await this.rootStore.apiClient.get(`products/${id}`);
        let product = Unwrap.result<Product>(res);
        runInAction(() => {
            this._blockHelper.unblock(id);
            let res = product ?? new NotFoundProduct(id);
            this.cacheProduct(res);
        });
    }

    private pushLastViewedProduct(product: Product) {
        let orgArr = this.lastViewedProducts;
        //Does the product already exist in the array?
        let index = orgArr.findIndex(a => a.id === product.id);

        let obj = { id: product.id, name: product.name }

        //It's already in the array and it's the first entry
        if (index === 0) {
            this.lastViewedProducts[0] = obj;
            return;
        }

        let newArr = [...this.lastViewedProducts];

        newArr.unshift(obj);
        if (index === -1) {
            this.lastViewedProducts = newArr.slice(0, 5);
        }
        else {
            this.lastViewedProducts = newArr.filter((_, indx) => indx !== index + 1).slice(0, 5);
        }
    }

    getProduct(id: string | null | undefined, bypassCache = false): Product | NotFoundProduct | null | undefined {
        if (!id) return null;
        let product: Product | undefined | NotFoundProduct = undefined;
        //If we're bypassing the cache, or if we can't find the product in the cache, let's get it.
        if (bypassCache || !(product = this._products?.find(a => a.id === id))) {
            this.fetchProduct(id);
        }
        // if (this.isProduct(product)) this.pushLastViewedProduct(product);
        return product;
    }

}