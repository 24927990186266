import React, { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import useRootStore from "../../../hooks/rootStoreHook";
import { Unwrap } from "../../../utils/api/apiClient";
import Wrapper from "../../Wrapper";
import { Card, Form, Button, Container } from "react-bootstrap";
import { Product } from "../../../models/Product";
import ProductSelector from "../../Products/ProductSelector";
import Select from "react-select";
import { Country } from "../../../models/Country";

const GlobalLegislationFinder: React.FC<{ product?: Product; onLoaded }> = (
  props
) => {
  const { apiClient } = useRootStore();
  const [productLegalActs, setProductLegalActs] = useState<Product["legalActs"]>([]);
  const [selectedCountry, setSelectedCountry] = useState<string>();
  const [countries, setCountries] = useState<Country[]>([]);
  const [resultText, setResultText] = useState("");

  useEffect(() => {
    const handle = async () => {
      const res = await apiClient.get("/countries");
      const result = Unwrap.result(res);
      setCountries(result);
    };

    handle();
  }, []);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (productLegalActs && selectedCountry) {
      const documentNames = productLegalActs
        .map((document) => document.reference)
        .join(", ");
      const text = encodeURIComponent(`what is the equivalent of ${documentNames} in ${selectedCountry}`);
      console.log(documentNames)
      console.log(productLegalActs)
      const res = await apiClient.post(
        `/tools/GlobalStandardFinder/AskFinder/?text=${text}`
      );
      setResultText(res.data || "No results found.");
    }
  };

  return (
    <Wrapper headline="Global Legislation Finder">
      <Container>
        <Card body className="b rounded">
          <Form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-6">
                <div style={{ padding: "0px 15px", gap: "10px" }}>
                  {!props.product?.id ? (
                    <ProductSelector
                      selectOnChange
                      onSelected={(val) => {
                        setProductLegalActs((val as Product)?.legalActs);
                      }}
                    />
                  ) : (
                    <Select isDisabled placeholder={props.product.name} />
                  )}
                </div>
              </div>

              <div className="col-6">
                <Select
                  options={countries.map((a) => ({ value: a.name!, label: a.name! }))}
                  onChange={(e) => setSelectedCountry(e?.value)}
                />
              </div>

              <div className="col-12 d-flex justify-content-center">
                <Button type="submit" className="mt-2" disabled={!selectedCountry || !productLegalActs}>
                  Search
                </Button>
              </div>

              <div className="col-12">
                <Form.Group controlId="formProductNotes">
                  <Form.Label>Result</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={20}
                    disabled
                    value={resultText}
                    onChange={(e) => setResultText(e.target.value)}
                  />
                </Form.Group>
              </div>
            </div>
          </Form>
        </Card>
      </Container>
    </Wrapper>
  );
};

export default observer(GlobalLegislationFinder);
