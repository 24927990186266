import React from "react";

export interface WrapperProps {
    children?: any,
    sectionProps?: object,
    contentClasses?: string,
    headline?: string,
    editable?: any
}

const Wrapper: React.FC<WrapperProps> = (props) => {

    const { children, sectionProps = {}, contentClasses = "", headline = "", editable } = props;

    return (
        <section className="section-container" {...sectionProps}>
            <div className={`content-wrapper animated fadeIn faster ${contentClasses}`}>
                {(headline || "").length > 0 ? <div className="content-heading d-block">
                    <h2>
                        {headline}
                    </h2>
                </div> : ""}
                {editable ?
                    <div className="content-heading d-block">
                        {<h2>{editable}</h2>}
                    </div>
                    : ""}
                {children}
            </div>
        </section>)
}
export default Wrapper;